.avatar-item--box {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.avatar-item {
    align-items: center;
    background-color: $mine-shaft;
    display: inline-flex;
    justify-content: center;
    margin-left: $avatar-item-margin-horizontal;
    margin-right: $avatar-item-margin-horizontal;
    margin-top: $avatar-item-margin-top;
    position: relative;
    border-radius: 100%;
  
    & img {
        align-self: center;
        border: .1vw solid transparent;
        border-radius: 100%;
        height: $avatar-item-width;
        position: relative;
        width: $avatar-item-width;
    }

    &--icon {
        border: .2vw solid $color-secondary;
        border-radius: 100%;
        height: $avatar-item-width;
        padding: 2vw;
        width: $avatar-item-width;
    }

    &--icon-check {
        background-color: $california;
        border-radius: 100%;
        height: $avatar-item-check-width;
        padding: .4vw;
        position: absolute;
        right: 0;
        top: 0;
        width: $avatar-item-check-width;
        z-index: 2;
    }

    &--icon-pen {
        background-color: $white;
        border-radius: 100%;
        bottom: 0;
        height: $avatar-item-check-width;
        fill: $cod-gray !important;
        display: flex;
        padding: .4vw;
        position: absolute;
        right: 0;
        width: $avatar-item-check-width;
        z-index: 2;
    }
  
    &--active {
        cursor: pointer;
      
        & img {
            border: .3vw solid $california;
            display: block;
            transform-origin: center;
        }
    }

    &--active &--icon {
        border: .3vw solid $california;
    }

    &--catsList {
        margin-top: 0;
    }

    &--name {
        height: 5vh;
        margin-bottom: 2vh;
        margin-top: 4vh;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 10vw;
    }

    &--name--catsList {
        height: 5vh;
        margin-bottom: 0;
        margin-top: 1vh;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 10vw;
    }

    &--error {
        color: $flamingo !important;
        height: 5vh;
        margin-bottom: 4vh;
        margin-top: 2vh;
    }

    &--button {
        height: 5vh;
        margin-left: $avatar-item-margin-horizontal - .5vw !important;
        margin-right: $avatar-item-margin-horizontal - .5vw !important;
        width: $avatar-item-width + 1vw;
    }

    &--button-save.button--round {
        align-self: center;
        border-radius: 1vh !important;
        height: 7vh;
        width: $avatar-item-width + 4vw;
    }

    &--space-no-button {
        height: 5vh;
    }
}

.avatar-list-title {
    margin-bottom: 5vh !important;
}

.avatar-list-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    overflow-y: scroll;
    padding-bottom: 50vh;
    position: relative;
    scroll-behavior: smooth;
    @include hide-scrollbar();
}