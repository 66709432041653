$nobel: rgb(179, 179, 179);
$california: rgb(251, 160, 11);
$flamingo: rgb(239, 70, 70);
$white: rgb(255, 255, 255);
$mine-shaft: rgb(35, 35, 35);
$cod-gray: rgb(9, 9, 9);
$green: rgb(84, 194, 84);

$transparent: transparent;

// THEME V10
$silver-chalice: rgb(174, 174, 174);
$dove-gray: rgb(69, 69, 69);
$mine-shaft-transparent: rgba(25, 25, 25, 0.65);
