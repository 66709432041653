.category-page {
    padding: 2vw 6vw;

    &--title-box {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 2vw;
    }

    &--content.search__content {
        height: 70vh;
        margin-bottom: 0;
        margin-top: 0;
    }
}