.list {
  align-items: center;
  display: flex;
  overflow-x: hidden;
  padding: $list-padding;
  scroll-behavior: smooth;

  &--catsList {
    padding: 3vh 1vw 3vh 1vw;
  }

  &--gap {
    padding: $list-hz-padding-right;
  }

  &--wrapped {
    flex-wrap: wrap;
  }

  &--center {
    justify-content: center;
  }

  &--channelRow {
    padding-bottom: 15vh;
  }
}
