.popup-container {
    align-items: center;
    background-color: #111111cc;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 2;

    .popup-box {
        align-items: center;
        background-color: $cod-gray;
        border-radius: $border-radius;
        display: flex;
        flex-direction: column;
        height: 40vh;
        justify-content: center;
        width: 40vw;

        .popup-btn {
            margin: 8vh 2vh 0 2vh;
        }
    }
}