.channels-program-title-box {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: .5vh;
}

.channels-program-days-arrow-hidden {
    opacity: 0;
}

.channels-program-days-item-hidden {
    overflow: hidden;
    width: 0;
}

.channels-program-days-box {
    border: .3vw solid transparent;
    border-radius: $border-radius;
    display: block;
    margin: 0 1vh;
    width: 6vw;

    .channels-program-days-item {
        font-weight: bold;
        margin: 1.5vh 0;
    }

    &.focused {
        border: .3vw solid $california;
        cursor: pointer;
    }

    &.selected {
        background-color: #fba00bbb;

        .channels-program-days-item {
            color: $cod-gray;
        }
    }
}

.channels-program-box {
    align-items: flex-start;
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    .channels-program-line-box {
        align-items: flex-start;
        display: flex;
        margin-left: 1vw;
        margin-right: 1vw;

        .channels-program-list-box {
            height: 70vh;
            margin-bottom: 0;
            margin-top: 9vh;
            overflow-y: scroll;
            padding-bottom: 4vh;
            padding-left: 2vw;
            scroll-behavior: smooth;
            scrollbar-width: none;
            -ms-overflow-style: none;
        }
    }
}

.channels-program-line-title-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .avatar-item {
        margin: 4vh 0 0 0;
    }

    &.channel-page-line-title-box .avatar-item {
        margin: 0 2vw;
    }

    p {
        margin-top: 2vh;
    }
}

.channels-program-item-info-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    transform: translate(0, 4vh);
    z-index: 2;

    .channels-program-item-time { 
        // text-shadow: 2px 0 0 #111, 2px 2px 0 #111, 0 2px 0 #111, -2px 2px 0 #111, -2px 0 0 #111, -2px -2px 0 #111, 0 -2px 0 #111, 2px -2px 0 #111111;
        text-shadow: 1px 0 0 #11111177, 1px 1px 0 #11111177, 0 1px 0 #11111177, -1px 1px 0 #11111177, -1px 0 0 #11111177, -1px -1px 0 #11111177, 0 -1px 0 #11111177, 1px -1px 0 #11111177;
        transform: translate(.2vw, 0);
    }

    .channels-program-item-inLive {
        background-color: $silver-chalice;
        border: .1vw solid #11111177;
        border-radius: .72vw;
        font-weight: bold;
        padding: .2vh 1vh;
        text-shadow: 1px 0 0 #11111177, 1px 1px 0 #11111177, 0 1px 0 #11111177, -1px 1px 0 #11111177, -1px 0 0 #11111177, -1px -1px 0 #11111177, 0 -1px 0 #11111177, 1px -1px 0 #11111177;
        transform: translate(-1.5vw, 0);

        .channels-program-item-inLive-dot {
            background-color: $color-error;
            border-radius: 100%;
            display: inline-block;
            height: 1.6vh;
            margin-left: 1vh;
            width: 1.6vh;
        }
    }
}

.channel-title-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 2vh;
    margin-right: 12vw;
    margin-bottom: 5vh;

    .channels-title-box {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .channel-not-selected {
            opacity: .5;
        }
    }
}

.f-grow { flex-grow: 1; }

.channel-page {
    height: 100vh;
    padding-bottom: 20vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;
    width: 100vw;
}

.channel-main-movie-box {
    align-items: center;
    display: flex;
    justify-content: space-around;
    width: 90vw;

    .channel-main-movie {
        margin: 5vh 0 0 0;
    }

    .channel-main-movie-info {
        padding: 0 5vh;
    }
}

.channel-main-movie-btns {
    margin-bottom: 4vh;
    margin-left: 4vh;
}

.avatar-item.avatar-item--channel-main-movie-distribution {
    margin-top: 1.5vw;

    img {
        border-radius: 100%;
        height: 6vw !important;
        width: 6vw !important;
    }
} 

.avatar-item.avatar-item--channel-thumbnail {
    margin-top: 1.5vw;

    img {
        border: .1vw solid $cod-gray;
        border-radius: 100%;
        height: 4vw !important;
        width: 4vw !important;
    }
} 

.channel-page-line-distribution-box {
    align-items: center;
    display: flex;
    justify-content: flex-start;
}