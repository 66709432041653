.list-item {
  align-items: center;
  background-color: $mine-shaft;
  border-radius: $border-radius;
  display: inline-flex;
  justify-content: center;
  margin-right: $list-item-margin-right;
  position: relative;

  &::after {
    @include border-focus($border-radius, $border-active-width, transparent);
    @include transition(border-color .2s ease-out, transform .2s ease-in);
  } 

  & img {
    @include transition(transform .2s ease-out);
  }

  &--active {
    cursor: pointer;
    
    & img {
      display: block;
      @include transform(scale(1.04)); //TODO variable
      transform-origin: center;
    }

    &::after {    
      @include border-focus($border-radius, $border-active-width, $color-focus, 1.04);
    }

    &.list-item--thumbnail {
      &:not(.list-item--xl, .list-item--xxl) {
        .list-item__badgeNew, .list-item__badgeRecurrence {
          left: calc( -0.02 * $list-item-width );
          top: calc( -0.02 * $list-item-height );
          transition: left .2s ease-out, top .2s ease-out;
        }

        .list-item__badgeRecurrence {
          top: 4vh + calc( -0.02 * $list-item-height );
        }
      }

      &.list-item--xl, &.list-item--xxl {
        .list-item__badgeNew, .list-item__badgeRecurrence {
          left: calc( -0.02 * 35vh * 16 / 9 );
          top: calc( -0.02 * 35vh );
          transition: left .2s ease-out, top .2s ease-out;
        }

        .list-item__badgeRecurrence {
          top: 4vh + calc( -0.02 * 35vh );
        }
      }  
    }

    &.list-item--square {
      .list-item__badgeNew, .list-item__badgeRecurrence {
        left: calc( -0.02 * $list-item-width );
        top: calc( -0.02 * $list-item-width );
        transition: left .2s ease-out, top .2s ease-out;
      }

      .list-item__badgeRecurrence {
        top: 4vh + calc( -0.02 * $list-item-width );
      }
    }

    &.list-item--poster {
      &:not(.list-item--xs, .list-item--xxs, .list-item--xl, .list-item--xxl) {
        .list-item__badgeNew, .list-item__badgeRecurrence {
          left: calc( -0.02 * 45vh * 3 / 4 );
          top: calc( -0.02 * 45vh );
          transition: left .2s ease-out, top .2s ease-out;
        }

        .list-item__badgeRecurrence {
          top: 4vh + calc( -0.02 * 45vh );
        }
      }
  
      &.list-item--xs {
        .list-item__badgeNew, .list-item__badgeRecurrence {
          left: calc( -0.02 * 35vh * 3 / 4 );
          top: calc( -0.02 * 35vh );
          transition: left .2s ease-out, top .2s ease-out;
        }

        .list-item__badgeRecurrence {
          top: 4vh + calc( -0.02 * 35vh );
        }
      }
  
      &.list-item--xxs {
        .list-item__badgeNew, .list-item__badgeRecurrence {
          left: calc( -0.02 * 30vh * 3 / 4 );
          top: calc( -0.02 * 30vh );
          transition: left .2s ease-out, top .2s ease-out;
        }

        .list-item__badgeRecurrence {
          top: 4vh + calc( -0.02 * 30vh );
        }
      }
  
      &.list-item--xl, &.list-item--xxl {
        .list-item__badgeNew, .list-item__badgeRecurrence {
          left: calc( -0.02 * $list-item-width );
          top: calc( -0.02 * $list-item-width * 4 / 3 );
          transition: left .2s ease-out, top .2s ease-out;
        }

        .list-item__badgeRecurrence {
          top: 4vh + calc( -0.02 * $list-item-width * 4 / 3 );
        }
      }
    }

    &.list-item--posterlong {
      &:not(.list-item--xs, .list-item--xxs, .list-item--xl, .list-item--xxl) {
        .list-item__badgeNew, .list-item__badgeRecurrence {
          left: calc( -0.02 * 46vh * 9 / 16 );
          top: calc( -0.02 * 46vh );
          transition: left .2s ease-out, top .2s ease-out;
        }

        .list-item__badgeRecurrence {
          top: 4vh + calc( -0.02 * 46vh );
        }
      }
  
      &.list-item--xs {
        .list-item__badgeNew, .list-item__badgeRecurrence {
          left: calc( -0.02 * 43vh * 9 / 16 );
          top: calc( -0.02 * 43vh );
          transition: left .2s ease-out, top .2s ease-out;
        }

        .list-item__badgeRecurrence {
          top: 4vh + calc( -0.02 * 43vh );
        }
      }
  
      &.list-item--xxs {
        .list-item__badgeNew, .list-item__badgeRecurrence {
          left: calc( -0.02 * 40vh * 9 / 16 );
          top: calc( -0.02 * 40vh );
          transition: left .2s ease-out, top .2s ease-out;
        }

        .list-item__badgeRecurrence {
          top: 4vh + calc( -0.02 * 40vh );
        }
      }
  
      &.list-item--xl, &.list-item--xxl {
        .list-item__badgeNew, .list-item__badgeRecurrence {
          left: calc( -0.02 * $list-item-width );
          top: calc( -0.02 * $list-item-width * 16 / 9 );
          transition: left .2s ease-out, top .2s ease-out;
        }

        .list-item__badgeRecurrence {
          top: 4vh + calc( -0.02 * $list-item-width * 16 / 9 );
        }
      }
    }
  }

  &--thumbnail {
    &:not(.list-item--xl, .list-item--xxl) {
      &,
      & img,
      & .loader--wrapper {
        height: $list-item-height;
        width: $list-item-width;
      }
    }
  
    &.list-item--xl, &.list-item--xxl {
      &,
      & img,
      & .loader--wrapper {
        height: 35vh;
        width: calc(35vh * 16 / 9);
      }
    }  
  }

  &--square {
    &,
    & img,
    & .loader--wrapper {
      height: $list-item-width;
    }  
  }

  &--poster {
    &:not(.list-item--xs, .list-item--xxs, .list-item--xl, .list-item--xxl) {
      &,
      & img,
      & .loader--wrapper {
        height: 45vh;
        width: calc(45vh * 3 / 4);
      }
    }

    &.list-item--xs {
      &,
      & img,
      & .loader--wrapper {
        height: 35vh;
        width: calc(35vh * 3 / 4);
      }
    }

    &.list-item--xxs {
      &,
      & img,
      & .loader--wrapper {
        height: 30vh;
        width: calc(30vh * 3 / 4);
      }
    }

    &.list-item--xl, &.list-item--xxl {
      &,
      & img,
      & .loader--wrapper {
        height: calc($list-item-width * 4 / 3);
      }
    }
  }

  &--posterlong {
    &:not(.list-item--xs, .list-item--xxs, .list-item--xl, .list-item--xxl) {
      &,
      & img,
      & .loader--wrapper {
        height: 46vh;
        width: calc(46vh * 9 / 16);
      }
    }

    &.list-item--xs {
      &,
      & img,
      & .loader--wrapper {
        height: 43vh;
        width: calc(43vh * 9 / 16);
      }
    }

    &.list-item--xxs {
      &,
      & img,
      & .loader--wrapper {
        height: 40vh;
        width: calc(40vh * 9 / 16);
      }
    }

    &.list-item--xl, &.list-item--xxl {
      &,
      & img,
      & .loader--wrapper {
        height: calc($list-item-width * 16 / 9);
      }
    }
  }

  &__startAt {
    left: 1vh;
    // text-shadow: 2px 0 0 #111, 2px 2px 0 #111, 0 2px 0 #111, -2px 2px 0 #111, -2px 0 0 #111, -2px -2px 0 #111, 0 -2px 0 #111, 2px -2px 0 #111111;
    text-shadow: 1px 0 0 #11111177, 1px 1px 0 #11111177, 0 1px 0 #11111177, -1px 1px 0 #11111177, -1px 0 0 #11111177, -1px -1px 0 #11111177, 0 -1px 0 #11111177, 1px -1px 0 #11111177;
    top: 1vh;
    z-index: 2;
  }

  &__channelBox {
    align-items: center;
    display: flex;
    left: 0;
    margin-bottom: 1vw;
    position: absolute;
    top: calc(100% + 2 * $border-active-width - .6vw);
    width: 100%;

    p {
      line-height: 1.2 !important;
      margin-left: 6vw;
      margin-top: 1.5vw;
      text-shadow: 1px 0 0 #11111177, 1px 1px 0 #11111177, 0 1px 0 #11111177, -1px 1px 0 #11111177, -1px 0 0 #11111177, -1px -1px 0 #11111177, 0 -1px 0 #11111177, 1px -1px 0 #11111177;
    }
  }

  &__inLive {
    background-color: $silver-chalice;
    border: .1vw solid #11111177;
    border-radius: .72vw;
    font-weight: bold;
    padding: .2vh 1vh;
    right: 2vh;
    text-shadow: 1px 0 0 #11111177, 1px 1px 0 #11111177, 0 1px 0 #11111177, -1px 1px 0 #11111177, -1px 0 0 #11111177, -1px -1px 0 #11111177, 0 -1px 0 #11111177, 1px -1px 0 #11111177;
    top: 2vh;
  }

  &__inLiveDot {
    background-color: $color-error;
    border-radius: 100%;
    display: inline-block;
    height: 1.6vh;
    margin-left: 1vh;
    width: 1.6vh;
  }

  & .loader--wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
  }

  & img {
    object-fit: cover;
    border-radius: $border-radius;
  }

  & .text {
    position: absolute;
    line-height: 1.5;
    text-align: center;
    transform-origin: center;
    z-index: 30;
  }

  & .loader--wrapper {
    position: absolute;
  }

  &__progress-bar {
    background: $white;
    border-radius: .5vh;
    top: calc(100% + 2 * $border-active-width + 0.2vw);//.4vw = border-active width
    height: .6vh;
    position: absolute;
    width: 100%;

    &--progression {
      background: $california;
      border-radius: .5vh;
      height: .6vh;
      left: 0;
      position: absolute;
      top: 0;
      z-index: 10;
    }
  }

  &__badgeNew {
    background-color: $california;
    border-bottom-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
    left: 0;
    height: 4vh;
    padding: 0 1vh;
    position: absolute;
    top: 0;
    transition: left .2s ease-out, top .2s ease-out;
  }

  &__badgeRecurrence {
    background-color: $white;
    border-bottom-right-radius: $border-radius;
    color: $mine-shaft !important;
    left: 0;
    height: 4vh;
    padding: 0 1vh;
    position: absolute;
    top: 4vh;
    transition: left .2s ease-out, top .2s ease-out;
  }
}
