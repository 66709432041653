.text {
  @include font(balboa, light);
  font-size: $text-font-size;
  color: $color-secondary;
  line-height: $text-line-height;
  
  & > p {
    margin-bottom: $text-margin-bottom;
  }
  
  // Alignment
  &--center {
    text-align: center !important;
  }
  
  &--justify {
    text-align: justify;
  }
  
  // Color
  &--error {
    color: $color-error;
  }
  
  &--grey {
    color: $color-primary;
  }
  
  &--secondary {
    color: $color-focus;
  }
  
  &--success {
    color: $color-error;
  }
  
  // Font size
  &--large {
    font-size: $text-font-size-large;
  }
  
  &--small {
    font-size: $text-font-size-small;
  }
  
  &--xs {
    font-size: $text-font-size-xs;
  }
  
  &--xxs {
    font-size: $text-font-size-xxs;
  }
  
  // Shadow
  &--shadow {
    text-shadow: $shadow-text-thumbnail;
  }

  &--bold {
    font-weight: bold;
  }
}
